<template>
  <div class="specification-container">
    <!-- 属性table -->
    <div class="property-ctan">
      <div class="property-item">
        <div class="prop-key">属性</div>
        <div class="prop-value_ctan">属性值</div>
      </div>
      <div
        class="property-item"
        v-for="(prop, propIndex) in properties"
        :key="propIndex"
      >
        <div class="prop-key">
          <el-input v-model="prop.key" placeholder="" disabled>
            <template #append
              ><span class="close-btn" @click="removeKeyHandle(prop, propIndex)"
                >×</span
              ></template
            >
          </el-input>
        </div>
        <div class="prop-value_ctan">
          <div
            class="value-item"
            v-for="(propVal, valIndex) in prop.values"
            :key="valIndex"
          >
            <el-input v-model="prop.values[valIndex]" placeholder="" disabled>
              <template #append
                ><span
                  class="close-btn"
                  @click="removeValueHandle(prop, propVal, valIndex)"
                  >×</span
                ></template
              >
            </el-input>
          </div>
          <div class="value-item">
            <el-button
              v-if="!prop.temp.showNewValInput"
              type="primary"
              @click="prop.temp.showNewValInput = true"
              >添加属性值</el-button
            >
            <div v-else class="new-value_input">
              <el-input
                v-model="prop.temp.newPropVal"
                placeholder=""
              ></el-input>
              <el-button
                type="danger"
                @click="
                  prop.temp.showNewValInput = false;
                  prop.temp.newPropVal = '';
                "
                >取消</el-button
              >
              <el-button type="primary" @click="addValues(prop)"
                >确定</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="property-item">
        <div class="prop-key">
          <!-- 添加属性 -->
          <el-button
            v-if="!showNewPropInput"
            type="primary"
            @click="showNewPropInput = true"
            >添加属性</el-button
          >
          <div v-else class="new-prop_input">
            <el-input v-model="newProp" placeholder=""></el-input>
            <el-button
              type="danger"
              @click="
                showNewPropInput = false;
                newProp = '';
              "
              >取消</el-button
            >
            <el-button type="primary" @click="addProperty">确定</el-button>
          </div>
        </div>
        <div class="prop-value_ctan"></div>
      </div>
    </div>

    <!-- lines -->
    <div v-if="lines.length" class="prop-lines">
      <el-table :data="lines" style="width: 100%" border stripe>
        <el-table-column
          v-for="(item, index) in propKes"
          :key="index"
          :prop="item"
          :label="item"
          min-width="80"
        >
        </el-table-column>
        <el-table-column prop="image" label="图片" width="120">
          <template v-slot="scope">
            <uploads
              v-model="scope.row.image"
              simple
              :multiple="false"
            ></uploads>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="编号">
          <template v-slot="scope">
            <el-input v-model="scope.row.code" type="number"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="market_price" label="市场价">
          <template v-slot="scope">
            <el-input v-model="scope.row.market_price" type="number"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="sales_price" label="销售价">
          <template v-slot="scope">
            <el-input v-model="scope.row.sales_price" type="number"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="stock" label="库存">
          <template v-slot="scope">
            <el-input v-model="scope.row.stock" type="number"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="sales" label="虚拟销售量" min-width="100">
          <template v-slot="scope">
            <el-input v-model="scope.row.sales" type="number"></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { remove, cloneDeep } from "lodash";
import { msgErr } from "@/helpers/message";
export default {
  props: {
    specList: {
      type: Array,
      default: function(){
        return [];
      },
    },
    specTableList: {
      type: Array,
      default: function(){
        return [];
      },
    },
  },
  data() {
    return {
      properties: [
        // eg:
        // { key: "温度", values: ["常温", "低温", "高温", "沸腾"] },
        // { key: "冰", values: ["无冰", "少冰", "多冰", "全是冰"] },
      ],
      lines: [],
      showNewPropInput: false,
      newProp: "",
    };
  },
  computed: {
    propKes() {
      return this.properties.map((item) => `${item.key}`);
    },
  },
  watch: {
    specList: {
      handler(val) {
        if (val) {
          const propertiesJson = cloneDeep(val);
          propertiesJson.forEach((prop) => {
            const tempProp = {
              key: prop.name,
              values: [],
              temp: { newPropVal: "", showNewValInput: false },
            };
            this.properties.push(tempProp);

            prop.child.forEach((newVal) => {
              tempProp.values.push(newVal);
              const otherProps = this.properties.filter(
                (item) => item.key !== tempProp.key
              );
              this.addValue2Line(tempProp.key, newVal, otherProps || []);
            });
          });
        }
      },
      immediate: true,
    },
    specTableList: {
      handler(val) {
        if (val) {
          // 为了保证properties有值，nextTick
          this.$nextTick(() => {
            console.log("specTableList================");
            const linesJson = cloneDeep(val);

            const allPropKeys = this.properties.map((ap) => ap.key);
            const getKey = (line) => {
              const cacheKeys = [];
              Object.keys(line).forEach((lk) => {
                if (allPropKeys.includes(lk)) {
                  cacheKeys.push(line[lk]);
                }
              });
              return cacheKeys.join("-");
            };

            linesJson.forEach((lineJson) => {
              // lineJson的value转置,
              lineJson.value.forEach((valJson, index) => {
                lineJson[allPropKeys[index]] = valJson;
              });
              delete lineJson.value;

              const lineJsonKey = getKey(lineJson);
              this.lines.some((line) => {
                console.log(lineJsonKey + "***" + getKey(line));
                if (getKey(line) === lineJsonKey) {
                  Object.assign(line, lineJson);
                  return true;
                }
              });
            });
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    getResult() {
      const propertiesJson = [];
      this.properties.forEach((prop) => {
        propertiesJson.push({
          name: prop.key,
          child: prop.values,
        });
      });

      const linesJson = [];
      const oPropKeys = this.properties.map((p) => p.key);
      this.lines.forEach((line) => {
        const values = [];
        oPropKeys.forEach((key) => {
          values.push(line[key]);
        });
        linesJson.push({
          code: line.code,
          image: line.image,
          market_price: line.market_price,
          sales: line.sales,
          sales_price: line.sales_price,
          stock: line.stock,
          value: values,
        });
      });

      return {
        specList: propertiesJson,
        specTableList: linesJson,
      };
    },

    addValue2Line(key, value, otherProps) {
      console.log("addValue2Line", key, value, otherProps);
      if (otherProps.length && this.lines.length) {
        const oPropKeys = otherProps.map((op) => op.key);
        const cache = [];
        const getCacheKey = (line) => {
          const cacheKeys = [];
          Object.keys(line).forEach((lk) => {
            if (oPropKeys.includes(lk)) {
              cacheKeys.push(line[lk]);
            }
          });
          return cacheKeys.join("-");
        };
        const cacheLine = (line) => {
          cache.push(getCacheKey(line));
        };
        otherProps.forEach((otherItem) => {
          const otherKey = otherItem.key;
          const otherValues = otherItem.values;
          otherValues.forEach((oVal) => {
            this.lines.some((line) => {
              if (line[otherKey] === oVal) {
                // console.log(line[otherKey], oVal, line[key]);
                if (!line[key]) {
                  console.log(line[otherKey], oVal, line[key], value);
                  // line里面没有新增value对应的key，则直接为line增加这个key和value
                  line[key] = value;
                  this.initLineOthers(line);
                } else if (line[key] !== value) {
                  console.log(
                    line[otherKey],
                    oVal,
                    line[key],
                    value,
                    getCacheKey(line)
                  );
                  if (!cache.includes(getCacheKey(line))) {
                    // 未cache的line，才能copy
                    // 否则需要copy这个line,再更新key对应的value
                    const tempLine = cloneDeep(line);
                    cacheLine(cloneDeep(tempLine)); // copy后的需要暂存，之后不再copy这个line
                    tempLine[key] = value;
                    this.initLineOthers(tempLine);
                    this.lines.push(tempLine);
                  }
                }
              }
            });
          });
        });
      } else {
        console.log("else", key, value);
        const tempLine = {
          [key]: value,
        };
        this.initLineOthers(tempLine);
        this.lines.push(tempLine);
      }
      // console.log(this.lines);
    },
    removeValue2Line(key, propVal) {
      remove(this.lines, (line) => {
        return line[key] === propVal;
      });
    },
    removePorp2Line(key) {
      this.lines.forEach((line) => {
        delete line[key];
      });
      // 结算，空line需要移除
      const propKeys = this.properties.map((prop) => prop.key);
      remove(
        this.lines,
        (line) =>
          !propKeys.find((propKey) =>
            Object.prototype.hasOwnProperty.call(line, propKey)
          )
      );
    },
    addProperty() {
      if (this.properties.find((prop) => prop.key === this.newProp)) {
        msgErr("属性不能重复添加");
        return;
      }
      if (!this.newProp) {
        msgErr("属性不能为空");
        return;
      }
      const newProp = {
        key: this.newProp,
        values: [],
        temp: { newPropVal: "", showNewValInput: false },
      };
      this.properties.push(newProp);
      this.showNewPropInput = false;
      this.newProp = "";
    },
    addValues(prop) {
      const newVal = cloneDeep(prop.temp.newPropVal);
      if (prop.values.includes(newVal)) {
        msgErr("同一个属性的属性值不能重复");
        return;
      }
      if (!newVal) {
        msgErr("属性值不能为空");
        return;
      }
      prop.values.push(newVal);
      prop.temp.newPropVal = "";
      prop.temp.showNewValInput = false;
      const otherProps = this.properties.filter(
        (item) => item.key !== prop.key
      );
      this.addValue2Line(prop.key, newVal, otherProps || []);
    },
    removeKeyHandle(prop, propIndex) {
      prop.values.forEach((value, index) => {
        if (index === prop.values.length - 1) {
          this.removePorp2Line(prop.key);
        } else {
          this.removeValue2Line(prop.key, value);
        }
      });
      remove(this.properties, (item, index) => {
        return item.key === prop.key && index === propIndex;
      });
    },
    removeValueHandle(prop, propVal, valIndex) {
      if (prop.values.length > 1) {
        this.removeValue2Line(prop.key, propVal);
      } else {
        this.removePorp2Line(prop.key);
      }
      remove(prop.values, (item, index) => {
        return item === propVal && index === valIndex;
      });
    },

    initLineOthers(line) {
      line["image"] = "";
      line["sales"] = "0";
      line["market_price"] = "0";
      line["code"] = "0";
      line["sales_price"] = "0";
      line["stock"] = "0";
    },
  },
};
</script>

<style lang="scss" scoped>
.specification-container {
  width: 100%;
  // margin: 0 20px;
  .property-ctan {
    display: flex;
    flex-direction: column;
    border: 1px solid #cccccc;
    margin-bottom: 10px;

    .property-item {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .prop-key {
        border-right: 1px solid #cccccc;
        width: 50%;
        padding: 6px;
      }
      .prop-value_ctan {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        width: 50%;
        padding: 6px;

        .value-item {
        }

        .value-item + .value-item {
          margin-top: 4px;
        }
      }
      & + .property-item {
        border-top: 1px solid #cccccc;
      }

      .close-btn {
        display: block;
        height: 100%;
        line-height: 32px;
        width: 32px;
        text-align: center;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .new-value_input,
  .new-prop_input {
    display: flex;
    flex-direction: row;
  }
}
</style>
